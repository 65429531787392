@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "~@mozilla-protocol/core/protocol/css/includes/forms/lib" as mp_forms;
@use "../styles/color";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-xs;
  cursor: pointer;
  background-color: $color-blue-50;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-radius-sm;
  border: 2px solid transparent;
  color: $color-white;
  font-weight: 700;
  line-height: 1.25;

  @include text-body-md;

  &:hover:not(:disabled) {
    background-color: $color-blue-60;
    opacity: 1;
  }

  &:focus {
    border-color: mp_forms.$button-border-color-focus;
    box-shadow: mp_forms.$field-focus-ring;
    color: $color-white;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $color-light-gray-80;
  }

  &.is-destructive {
    background-color: $color-red-60;

    &:disabled {
      background-color: $color-red-10;
    }

    &:hover:not(:disabled) {
      background-color: $color-red-70;
      opacity: 1;
    }
  }

  &.is-secondary {
    background-color: transparent;
    border: none;
    color: $color-blue-50;

    &:disabled {
      opacity: 0.4;
    }

    &:active {
      color: $color-blue-70;
    }

    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-blue-60;
    }
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $color-light-gray-80;
  color: $color-dark-gray-60; // darker text
  opacity: 0.4; // much more faded
  pointer-events: none;
  box-shadow: none;
  border-color: transparent;
  text-shadow: none;
  transition: none;
  filter: grayscale(100%);
}

.button.is-destructive.disabled {
  background-color: $color-red-10;
}

.button.is-secondary.disabled {
  background-color: transparent;
  color: $color-blue-50;
  opacity: 0.4;
}
